import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import { AllCountry } from "vuetify-country-search";

// let's write our custom rules here
//this is a url validation checking function
const validatorUrlValidator = val => {
    if (val === undefined || val === null || val.length === 0) {
      return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/
    /* eslint-enable no-useless-escape */
    return re.test(val)
  }

//this is for only checking numeric value
const validatorNumeric = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  return isNaN(val)
}

//number with dash for postal code validation
const numberDashValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  const re = /^[0-9_-]*$/i
  return re.test(val)
}

// phone number validation
const phoneValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true
  }
  const re = /^\+?[\d\s\-*]+\d$/
  return re.test(val)
}

// birth date validation
const birthdateValidator = val => {
  if(val === undefined || val === null || val.length === 0) {
    return true
  }
  let date = new Date()
  let today = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
  return new Date(val).getTime() >= new Date(today).getTime() ? false : true
}
// postal_code validation
const postalCodeValidator = (val, { country }) => {
  if (val === undefined || val === null || val.length === 0 || !country) {
    return true
  }
  const countryShortCode = typeof country === 'object' ? country.countryShortCode : AllCountry.filter((obj) => obj.countryCode === country)[0].countryShortCode;
  if (!postcodeValidatorExistsForCountry(countryShortCode)) return true;
  return postcodeValidator(val, countryShortCode);
}

// all the rules from vee-validate package are extend from here
// You can find all the rules from here https://vee-validate.logaretm.com/v3/guide/rules.html#rules
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  });
});

//let's create our own rules here
extend('url', {
    validate: validatorUrlValidator,
    message: 'Please provide a valid URL like https://example.com',
})

extend('is_numeric', {
  validate: validatorNumeric,
  message: '{_field_} Cannot contain only Numeric',
})

extend('number_dash', {
  validate: numberDashValidator,
  message: 'Provide a valid {_field_}',
})

extend('phone_num', {
  validate: phoneValidator,
  message: 'Provide a valid {_field_}',
})

extend('birth_date', {
  validate: birthdateValidator,
  message: 'Provide a valid {_field_}'
})

extend('postal_code', {
  params: ['country'],
  validate: postalCodeValidator,
  message: 'Provide a valid {_field_}'
})
