<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
    ></v-skeleton-loader>

    <v-card flat class="px-3" v-else>
      <v-card-text class="d-flex">
        <v-row>
          <v-col md="6" cols="12">
            <v-list>
              <v-list-item class="px-0">
                <v-list-item-content class="font-weight-bold">
                  Account Name:
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ account_name }}
                </v-list-item-content>
              </v-list-item>

              <v-list-item class="px-0">
                <v-list-item-content class="font-weight-bold">
                  Email:
                </v-list-item-content>
                <v-list-item-content class="align-end">
                  {{ accountData.email }}
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>

          <v-col md="6" cols="12">
            <v-row>
              <v-col cols="4">
                <v-avatar
                  rounded
                  size="120"
                  class="me-6">
                  <v-img v-if="!imgError" :src="accountData.photo" @error="onImgError()"></v-img>
                  <v-img v-else :src="$tools.defaultImage()"></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="8">
                <div>
                  <v-btn
                    small
                    color="primary"
                    class="me-3 mt-5"
                    @click="$refs.userPhoto.click()">
                    <v-icon class="d-sm-none">
                      {{ icons.mdiCloudUploadOutline }}
                    </v-icon>
                    <span class="d-none d-sm-block">Upload photo</span>
                  </v-btn>
                  <input
                    ref="userPhoto"
                    type="file"
                    @change="changePhoto"
                    accept=".jpg"
                    :hidden="true"/>
                  <v-btn
                    small
                    color="error"
                    outlined
                    @click="removePhoto"
                    class="mt-5">
                    Reset
                  </v-btn>
                  <p class="text-sm mt-5">
                    Allowed only JPG and size &lt; 1MB.
                  </p>
                </div>

              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-col v-if="isAccountCreating" cols="12">
        <v-skeleton-loader
          type="list-item-three-line, card-heading, actions"
        ></v-skeleton-loader>
      </v-col>
      <v-card-text class="mt-10" v-else>
        <v-form ref="accountData" @submit.prevent="handleSubmitClick()">
          <v-row>
            <v-col cols="12" md="6">
              <ValidationProvider
                name="First Name"
                rules="required|is_numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="First Name"
                  type="text"
                  v-model="accountData.first_name"
                  :rules="[required('First Name')]"
                  :error-messages="errors"
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="6">
              <ValidationProvider
                name="Last Name"
                rules="required|is_numeric"
                v-slot="{ errors }"
              >
                <v-text-field
                  label="Last Name"
                  type="text"
                  v-model="accountData.last_name"
                  :rules="[required('Last Name')]"
                  :error-messages="errors"
                  dense
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider
                name="Phone"
                v-slot="{ errors }"
              >
                <vue-tel-input-vuetify
                  v-model="accountData.phone"
                  :errorMessages="errors"
                  @input="onInput"
                  mode="international"
                  placeholder=" "
                  label="Phone Number"
                  :disabledFetchingCountry="true"
                  dense
                ></vue-tel-input-vuetify>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <ValidationProvider name="Gender" rules="required">
                <v-autocomplete
                  label="Gender"
                  :items="genderParams"
                  v-model="accountData.gender"
                  :rules="[required('Gender')]"
                  item-text="text"
                  item-value="value"
                  dense
                ></v-autocomplete>
              </ValidationProvider>
            </v-col>

            <v-col cols="12" md="4">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="accountData.birth_date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider
                    name="Date Of Birth"
                    rules="birth_date"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="accountData.birth_date"
                      label="Date Of Birth"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      dense
                      :error-messages="errors"
                    ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-date-picker v-model="accountData.birth_date" scrollable>
                  <v-spacer></v-spacer>
                  <v-btn small dense color="secondary" @click="modal = false">
                    Cancel
                  </v-btn>
                  <v-btn small dense color="primary" @click="$refs[`dialog`].save(accountData.birth_date)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>

            <v-col cols="12">
              <v-row>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    name="Country"
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <form autocomplete="off" @submit.prevent>
                      <v-autocomplete
                        v-model="accountData.country_code"
                        :items="AllCountry"
                        label="Country"
                        item-text="countryName"
                        item-value="countryCode"
                        :errorMessage="errors"
                        :rules="[required('Country')]"
                        dense
                      ></v-autocomplete>
                    </form>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    name="State"
                    v-slot="{ errors }"
                  >
                    <form autocomplete="off" @submit.prevent>
                      <v-autocomplete
                        v-model="accountData.state_or_province"
                        label="State or Province"
                        :items="
                          AllCountry.filter(
                            (obj) => obj.countryCode === accountData.country_code
                          )[0] &&
                          AllCountry.filter(
                            (obj) => obj.countryCode === accountData.country_code
                          )[0].regions
                        "
                        item-text="name"
                        item-value="name"
                        :errorMessage="errors"
                        dense
                      ></v-autocomplete>
                    </form>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    name="City"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="accountData.city"
                      :error-messages="errors"
                      label="City"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" md="6">
                  <ValidationProvider
                    name="Postal Code"
                    mode="eager"
                    v-slot="{ errors }"
                  >
                    <v-text-field
                      v-model="accountData.postal_code"
                      :error-messages="errors"
                      label="Postal Code"
                      dense
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>

                <v-col cols="12">
                  <ValidationProvider
                    name="Address"
                    v-slot="{ errors }"
                  >
                    <v-textarea
                      v-model="accountData.address"
                      :error-messages="errors"
                      label="Address"
                      rows="2"
                      dense
                    ></v-textarea>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" class="text-right">
              <v-btn
                small
                text
                type="reset"
                class="error--text rounded-pill px-5 me-3 font-weight-bold"
                @click.prevent="cancelClick"
              >
                Cancel
              </v-btn>
              <v-btn
                small
                type="submit"
                class="primary rounded-pill px-5"
              > Save changes
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    
    <DialogModal
      v-model="showModal"
      :message="modal_msg"
      :cancelButton="false"
      title="Error"
      confirmButtonText="OK"
      headlineClass="headline error white--text"
      confirmBtnClass="error rounded-pill px-3"
      @onConfirmation="showModal = false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  LOAD_ORGANIZATION,
  REFRESHTOKEN,
  COMPLETE_REGISTRATION,
  EDIT_USER_PROFILE,
  MEMBER_SETUP,
  ADD_ALERT
} from "@/store/_actiontypes";
import * as firestoreService from '@/database/firestore'
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { AllCountry } from "vuetify-country-search";
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import validations from "@/utils/validations";
import DialogModal from "@/components/common/DialogModal";
import "@/utils/vee-validate";


export default {
  props: {
    genderParams: {
      type: Array,
      default() {
        return [
          {text: "Male", value: "male"},
          {text: "Female", value: "female"},
          {text: "Other", value: "other"}
        ];
      },
    },
  },
  components: {
    ValidationObserver,
    ValidationProvider,
    DialogModal,
  },
  data: () => {
    return {
      ...validations,
      AllCountry,
      imgError: false,
      showModal: false,
      isLoading: true,
      invitedUser: false,
      isAccountCreating: false,
      modal: false,
      modal_msg: "",
      back_to: "",
      account_type: "",
      account_name: "",
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
      accountData: (state) => state.account.organization,
      loading: (state) => state.loader.loading,
    }),
  },
  created(){
    let parsedJWTJson = this.$tools.parseJwt(this.user.access_token);
    if(parsedJWTJson["ast"] == "active" && parsedJWTJson["ust"] == "created"){
      this.invitedUser = true;
    }

    if(this.$tools.timeToNextToken() < 300){
      this.REFRESHTOKEN({
        refresh_token: this.user.refresh_token
      }).then((response) => {
        this.loadProfileData();
      })
    } else{
      this.loadProfileData();
    }
  },
  methods: {
    ...mapActions("account", [
      LOAD_ORGANIZATION,
      REFRESHTOKEN,
      COMPLETE_REGISTRATION,
      EDIT_USER_PROFILE
    ]),
    ...mapActions("member", [MEMBER_SETUP]),
    ...mapActions("alert", [ADD_ALERT]),
    
    loadProfileData(){
      this.getIp();
      this.LOAD_ORGANIZATION().then((res) => {
        this.isLoading = false;
        this.account_type = res.data.account.type;
        this.account_name = res.data.account.account_name;
        this.accountData.photo = this.$tools.getEnv("VUE_APP_FILE_SERVER")+res.data.photo;
      }).catch(() => {
        this.isLoading = false;
      });
    },

    handleSubmitClick(){
      if (!this.$refs.accountData.validate()) return;

      let userData = {
        account_name: this.account_name,
        type: this.account_type,
        first_name: this.accountData.first_name,
        last_name: this.accountData.last_name,
        middle_name: "",
        ...(this.accountData.birth_date ? {birth_date: this.accountData.birth_date} : {}),
        ...(this.accountData.gender ? {gender: this.accountData.gender.toLowerCase()} : {}),
        phone: (this.accountData.phoneInt?this.accountData.phoneInt.replaceAll(" ",""):""),
        ip_address: this.accountData.ip_address,
        country_code: this.accountData.country_code,
        state_or_province: this.accountData.state_or_province,
        city: this.accountData.city,
        postal_code: this.accountData.postal_code,
        address: this.accountData.address,
        photo: this.accountData.photo,
        chat_id: this.accountData.chat_id,
      }

      this.UpsertRoom(userData);

      this.isAccountCreating = true;
      if(this.$route.name === 'setup-accounts'){
        if(!this.invitedUser){
          this.COMPLETE_REGISTRATION({ userData }).then((res) => {
            this.$store.dispatch(`alert/${ADD_ALERT}`,
              {
                message: "Account Updated Successfully",
                color: "success",
              },
              { root: true }
            );
            this.isAccountCreating = false;
          }).catch((err) => {
            this.isAccountCreating = false;
          });
        } else{
          this.MEMBER_SETUP({ userData }).then((res) => {
            this.REFRESHTOKEN({
              refresh_token: this.user.refresh_token
            }).then((response) => {
              this.$router.push("/overview?complete=true");
            });
            this.isAccountCreating = false;
          }).catch((err) => {
            this.isAccountCreating = false;
          });
        }
      } else{
        this.EDIT_USER_PROFILE({ userData }).then((res) => {
          this.$store.dispatch(`alert/${ADD_ALERT}`,
            {
              message: "Profile Updated Successfully",
              color: "success",
            },
            { root: true }
          );
          this.isAccountCreating = false;
        }).catch((err) => {
          this.isAccountCreating = false;
        });
      }
    },
    cancelClick(){
      if(this.$route.name === 'setup-accounts'){
        this.$router.push({path: '/signin'});
      } else{
        this.$router.back();
      }
    },
    onInput(formattedNumber, { number, valid, country }){
      this.accountData.phoneInt = number.international;
    },
    changePhoto(e){
      var files = e.target.files || e.dataTransfer.files;
      if(!files.length) return;
      if(files[0].size > 1024 * 1024){
        e.preventDefault();
        this.showModal = true;
        this.modal_msg = 'File too big (> 1MB)';
        return;
      }
      this.createImage(files[0]);
    },
    createImage(file){
      var reader = new FileReader();
      reader.onload = (e) => {
        this.imgError = false;
        this.accountData.photo = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removePhoto(e){
      this.accountData.photo = this.$tools.defaultImage();
    },
    getIp(){
      fetch("https://api.ipify.org?format=json").then((res) => res.json()).then(({ ip }) => {
        this.accountData.ip_address = ip;
      }).catch((err) => {});
    },
		onImgError(){
			this.imgError = true;
		},
    async UpsertRoom(data){
      let username = this.getChatUsername(data);
      let chat_id  = data.chat_id ? data.chat_id : "null";
      let userdata = await firestoreService.getUser(chat_id);

      if(userdata.hasOwnProperty("username")){
        await firestoreService.updateUser(data.chat_id, { username: username });
      } else{
        const { id } = await firestoreService.addUser({
          username: username
        });
        await firestoreService.updateUser(id, { _id: id });

        await firestoreService.addRoom({
          users: [id, this.$tools.getEnv("VUE_APP_CHAT_ID")],
          lastUpdated: new Date()
        });
        this.$tools.setSession("chat", {id: id});
      }
    },
    getChatUsername(data){
      let username = data.account_name;
      if(data.first_name != "" && data.last_name != ""){
        username = data.first_name +" "+ data.last_name;
      } else if(data.first_name != ""){
        username = data.first_name;
      } else if(data.last_name != ""){
        username = data.last_name;
      }
      return username;
    },
  },
}
</script>

